// jQuery Scrollstop Plugin v1.2.0
// https://github.com/ssorallen/jquery-scrollstop
(function (factory) {
	// UMD[2] wrapper for jQuery plugins to work in AMD or in CommonJS.
	//
	// [2] https://github.com/umdjs/umd

	if (typeof define === 'function' && define.amd) {
		// AMD. Register as an anonymous module.
		define(['jquery'], factory);
	} else if (typeof exports === 'object') {
		// Node/CommonJS
		module.exports = factory(require('jquery'));
	} else {
		// Browser globals
		factory(jQuery);
	}
}(function ($) {
	// $.event.dispatch was undocumented and was deprecated in jQuery 1.7[1]. It
	// was replaced by $.event.handle in jQuery 1.9.
	//
	// Use the first of the available functions to support jQuery <1.8.
	//
	// [1] https://github.com/jquery/jquery-migrate/blob/master/src/event.js#L25
	var dispatch = $.event.dispatch || $.event.handle;

	var special = $.event.special,
			uid1 = 'D' + (+new Date()),
			uid2 = 'D' + (+new Date() + 1);

	special.scrollstart = {
		setup: function(data) {
			var _data = $.extend({
				latency: special.scrollstop.latency
			}, data);

			var timer,
					handler = function(evt) {
						var _self = this,
								_args = arguments;

						if (timer) {
							clearTimeout(timer);
						} else {
							evt.type = 'scrollstart';
							dispatch.apply(_self, _args);
						}

						timer = setTimeout(function() {
							timer = null;
						}, _data.latency);
					};

			$(this).bind('scroll', handler).data(uid1, handler);
		},
		teardown: function() {
			$(this).unbind('scroll', $(this).data(uid1));
		}
	};

	special.scrollstop = {
		latency: 250,
		setup: function(data) {
			var _data = $.extend({
				latency: special.scrollstop.latency
			}, data);

			var timer,
					handler = function(evt) {
						var _self = this,
								_args = arguments;

						if (timer) {
							clearTimeout(timer);
						}

						timer = setTimeout(function() {
							timer = null;
							evt.type = 'scrollstop';
							dispatch.apply(_self, _args);
						}, _data.latency);
					};

			$(this).bind('scroll', handler).data(uid2, handler);
		},
		teardown: function() {
			$(this).unbind('scroll', $(this).data(uid2));
		}
	};
}));